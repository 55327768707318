import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Form, Input, message, Select} from 'antd';
import qs from 'query-string';
import * as CartActions from '../../Actions/Cart';
import * as AppActions from '../../AppActions';
import CartItem from '../../Components/CartItem';
import {ChevronRight} from '@styled-icons/boxicons-regular/ChevronRight';
import {
  BREAK_POINTS,
  LOGISTIC_TYPE_DISPLAY,
  MEMBERSHIP,
} from '../../Domain/Constants';
import {navigate} from 'gatsby';
import useModal from '../../Hooks/useModal';
import Button from '../../Components/Button';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import renderLoginRequired from '../../Components/LoginRequiredUi';
import {req} from '../../Utils/ApiUtil';
import * as _ from 'lodash';

const {Option} = Select;

const getIsInTaiwan = (value) => {
  return (
    value !== '金門縣' &&
    value !== '澎湖縣' &&
    value !== '釣魚臺' &&
    value !== '連江縣' &&
    value !== '南海島'
  );
};

function getInitValue(cart, profile) {
  return {
    buyer_name: cart.buyer_name || profile.name,
    buyer_address: cart.buyer_address || profile.address,
    buyer_phone: cart.buyer_phone || profile.phone,
    buyer_email: cart.buyer_email || profile.email,
    buyer_city:
      cart.buyer_city || getIsInTaiwan(profile.city) ? profile.city : '',
    buyer_district:
      cart.buyer_district || getIsInTaiwan(profile.city)
        ? profile.district
        : '',
    buyer_zip: cart.buyer_zip || profile.zip,
    receiver_name: cart.receiver_name,
    receiver_address: cart.receiver_address,
    receiver_phone: cart.receiver_phone,
    receiver_email: cart.receiver_email,
    receiver_city: cart.receiver_city,
    receiver_district: cart.receiver_district,
    receiver_zip: cart.receiver_zip,
    order_note: cart.order_note,
  };
}

const INPUT_TYPES = {
  text: 'text',
  select: 'select',
  checkbox: 'checkbox',
  textarea: 'textarea',
};

const GeneralInput = (props) => {
  const {type} = props;
  const {options, ...rest} = props;
  return (
    <InputField>
      {type === INPUT_TYPES.text && <Input {...props} />}
      {type === INPUT_TYPES.select && (
        <Select
          {...rest}
          defaultValue={props.defaultValue || undefined}
          value={props.value || undefined}>
          {props.options.map((opt, index) => {
            return (
              <Option value={opt.value} key={index}>
                {!!opt.code ? `${opt.code} ` : ''}
                {opt.label}
              </Option>
            );
          })}
        </Select>
      )}
      {type === INPUT_TYPES.checkbox && (
        <div style={{display: 'flex', alignItems: 'baseline'}}>
          <input
            style={{marginRight: 8, backgroundColor: '#0f0'}}
            {...props}
            id={`field-${props.name}`}
          />
          <label htmlFor={`field-${props.name}`}>{props.label}</label>
        </div>
      )}
      {type === INPUT_TYPES.textarea && <Input.TextArea {...props} />}
    </InputField>
  );
};

function CheckoutInfo(props) {
  const [cart] = useOutlet('cart');
  const [user, setUser] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const {modalElem, modalRef} = useModal();
  const params = qs.parse(props.location.search);
  const mobile = dimension.rwd === 'mobile';
  const [values, setValues] = React.useState({});
  const [isReceiverSameAsBuyer, setIsReceiverSameAsBuyer] = useState(true);
  const [isRead, setIsRead] = useState(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] = useState(true);
  const [buyerCityOptions, setBuyerCityOptions] = useState([]);
  const [buyerTownOptions, setBuyerTownOptions] = useState([]);
  const [receiverCityOptions, setReceiverCityOptions] = useState([]);
  const [receiverTownOptions, setReceiverTownOptions] = useState([]);
  const [zip3, setZip3] = useState(null);
  const [home, setHome] = React.useState(null);

  React.useEffect(() => {
    if (user && user.data && cart) {
      setValues({
        ...getInitValue(cart, user.data),
      });
    }
  }, [user, cart]);

  React.useEffect(() => {
    const _fetchZip3 = async () => {
      const _resp = await req(
        'https://daiwanlang.netlify.app/api/%E8%A1%8C%E6%94%BF%E5%8D%80/zip3/get',
      );
      const main_taiwan = _resp.filter((record) => getIsInTaiwan(record.city));
      const result = _.groupBy(main_taiwan, (z) => {
        return z.city;
      });

      setBuyerCityOptions(
        Object.keys(result).map((c) => ({
          label: c,
          value: c,
        })),
      );

      setReceiverCityOptions(
        Object.keys(result).map((c) => ({
          label: c,
          value: c,
        })),
      );

      if (cart.buyer_city) {
        const districts = result[cart.buyer_city];
        setBuyerTownOptions(
          districts.map((z) => ({
            label: z.district,
            value: z.district,
            zip: z.zip,
          })),
        );
      }

      if (cart.receiver_city) {
        const districts = result[cart.receiver_city];
        setReceiverTownOptions(
          districts.map((z) => ({
            label: z.district,
            value: z.district,
            zip: z.zip,
          })),
        );
      }
      setZip3(result);
    };
    _fetchZip3();
  }, [cart.buyer_city, cart.receiver_city]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        actions.setLoading(true);
        let {results} = await actions.fetchDocuments('home', {}, ['-updated'], {
          limit: 1,
          offset: 0,
        });
        if (results.length === 0) {
          throw 'cannot get home data';
        }

        const home = results[0];
        setHome(home);
      } catch (ex) {
      } finally {
        actions.setLoading(false);
      }
    }

    fetchData();
  }, [actions]);

  useEffect(() => {
    if (params?.error) {
      if (params.error === 'stock_not_enough') {
        message.warn(
          '購物車內有庫存不足商品，系統已自動為您移除，請選擇其他商品或出貨日期，再加入購物車，謝謝',
          5,
        );
      } else if (params.error === 'try_again') {
        message.warn('系統忙碌中，請稍候重新再嘗試一次，謝謝', 5);
      }
    }
  }, [params?.error]);

  const onDisclaimerDetailClick = React.useCallback(
    (e) => {
      e.preventDefault();
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <DisclaimerWrapper>
            <h2>免責聲明</h2>
            <p style={{whiteSpace: 'pre-line'}}>
              {`
              ● 全商品為食品，為顧及商品新鮮有食用期限，除非商品有瑕疵，一經出貨則不接受退換貨。\n
              ● 爲確保品質，採接單製作，匯款完成後【不接受取消訂單】，匯款完成即代表您接受本店無義務退還訂單款項！\n
              ● 好吃的甜點為求新鮮我們選擇用低溫寄送來保持最佳狀態，因此運送我們採用「黑貓宅急便」全程低溫冷凍配送。\n
              ● 出貨包裝我們已經盡可能地做到防護，但是若因宅配過程中造成的商品損毀，請洽黑貓宅急便客服412-8888(手機請加02)進行處理。\n
              ● 恕我們不負因運送毀損而產生的任何損失。請注意：當您下單訂購並付款後，訂單即成立，並視同您已同意本店上述運送之免責聲明。\n
`}
            </p>
          </DisclaimerWrapper>
        ),
      });
    },
    [modalRef],
  );

  const getInputProps = useCallback(
    (field) => {
      const onChange = (e) =>
        setValues((prev) => ({
          ...prev,
          [field]: e.target.value,
        }));

      let value;
      if (field === 'isReceiverSameAsBuyer') {
        value = isReceiverSameAsBuyer;
      } else if (field === 'isRead') {
        value = isRead;
      } else if (field === 'shouldUpdateProfile') {
        value = shouldUpdateProfile;
      } else {
        value = values[field];
      }

      return {
        buyer_name: {
          name: field,
          value: value,
          placeholder: '購買人姓名',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: true, message: '購買人姓名為必填'},
            {
              pattern: /^([\p{Script=Han}]{2,5})$/u,
              message: '姓名限制中文 2~5 字',
            },
          ],
        },
        buyer_email: {
          name: field,
          value: value,
          placeholder: '請輸入您的電子郵件',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: true, message: '購買人電子郵件為必填'},
            {
              pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
              message:
                '請確認您的電子郵件地址為正確格式（ex.emailtest@gmail.com)',
            },
          ],
        },
        buyer_phone: {
          name: field,
          value: value,
          placeholder: '請輸入10碼的手機號碼',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: true, message: '購買人手機號碼為必填'},
            {pattern: /^09\d{8}$/, message: '購買人手機不合法'},
          ],
        },
        buyer_city: {
          name: field,
          value: value,
          placeholder: '請選擇縣市',
          defaultValue: '',
          type: INPUT_TYPES.select,
          options: buyerCityOptions,
          disabled: false,
          onChange: (value) => {
            setValues((prev) => ({
              ...prev,
              [field]: value,
              buyer_district: '',
            }));
            const districts = zip3[value];
            setBuyerTownOptions(
              districts.map((z) => ({
                label: z.district,
                value: z.district,
                zip: z.zip,
              })),
            );
          },
          rules: [{required: true, message: '購買人縣市為必填'}],
        },
        buyer_district: {
          name: field,
          value: value,
          placeholder: '請選擇鄉鎮',
          defaultValue: '',
          type: INPUT_TYPES.select,
          options: buyerTownOptions,
          disabled: buyerTownOptions.length <= 0,
          onChange: (value) => {
            setValues((prev) => ({
              ...prev,
              [field]: value,
              buyer_zip: buyerTownOptions.find((o) => o.value === value)?.zip,
            }));
          },
          rules: [{required: true, message: '購買人鄉鎮為必填'}],
        },
        buyer_address: {
          name: field,
          value: value,
          placeholder: '請輸入收件地址',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [{required: true, message: '購買人地址為必填'}],
        },
        receiver_name: {
          name: field,
          value: value,
          placeholder: '收件人姓名',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: !isReceiverSameAsBuyer, message: '收件人姓名為必填'},
            {
              pattern: /^([\p{Script=Han}]{2,5})$/u,
              message: '姓名限制中文 2~5 字',
            },
          ],
        },
        receiver_email: {
          name: field,
          value: value,
          placeholder: '請輸入您的電子郵件',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: !isReceiverSameAsBuyer, message: '收件人電子郵件為必填'},
            {
              pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
              message: '收件人電子郵件需為合法電子郵件',
            },
          ],
        },
        receiver_phone: {
          name: field,
          value: value,
          placeholder: '請輸入10碼的手機號碼',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: !isReceiverSameAsBuyer, message: '收件人手機號碼為必填'},
            {
              pattern: /^09\d{8}$/,
              message: '收件人手機不合法',
            },
          ],
        },
        receiver_city: {
          name: field,
          value: value,
          placeholder: '請選擇縣市',
          defaultValue: '',
          type: INPUT_TYPES.select,
          options: receiverCityOptions,
          disabled: false,
          onChange: (value) => {
            setValues((prev) => ({
              ...prev,
              [field]: value,
              receiver_district: '',
            }));
            const districts = zip3[value];
            setReceiverTownOptions(
              districts.map((z) => ({
                label: z.district,
                value: z.district,
                zip: z.zip,
              })),
            );
          },
          rules: [
            {required: !isReceiverSameAsBuyer, message: '收件人縣市為必填'},
          ],
        },
        receiver_district: {
          name: field,
          value: value,
          placeholder: '請選擇鄉鎮',
          defaultValue: '',
          type: INPUT_TYPES.select,
          options: receiverTownOptions,
          disabled: receiverTownOptions.length <= 0,
          onChange: (value) => {
            setValues((prev) => ({
              ...prev,
              receiver_zip: receiverTownOptions.find((o) => o.value === value)
                ?.zip,
              [field]: value,
            }));
          },
          rules: [
            {required: !isReceiverSameAsBuyer, message: '收件人鄉鎮為必填'},
          ],
        },
        receiver_address: {
          name: field,
          value: value,
          placeholder: '請輸入收件地址',
          defaultValue: '',
          type: INPUT_TYPES.text,
          onChange: onChange,
          disabled: false,
          rules: [
            {required: !isReceiverSameAsBuyer, message: '收件人地址為必填'},
          ],
        },
        order_note: {
          name: field,
          value: value,
          placeholder: '請輸入客戶備註（選填）',
          defaultValue: '',
          type: INPUT_TYPES.textarea,
          onChange: onChange,
          disabled: false,
          rules: [],
        },
        isReceiverSameAsBuyer: {
          name: field,
          checked: value,
          label: '收件人同購買人資料',
          type: INPUT_TYPES.checkbox,
          onChange: (e) => {
            const {checked} = e.target;
            setIsReceiverSameAsBuyer(checked);
          },
        },
        shouldUpdateProfile: {
          name: field,
          checked: value,
          label: '同步更新會員資料',
          type: INPUT_TYPES.checkbox,
          onChange: (e) => {
            const {checked} = e.target;
            setShouldUpdateProfile(checked);
          },
        },
        isRead: {
          name: field,
          checked: value,
          label: (
            <span>
              我已閱讀
              <a
                href="/"
                className="disclaimer-link"
                onClick={onDisclaimerDetailClick}>
                「免責聲明」
              </a>
              並同意
            </span>
          ),
          type: INPUT_TYPES.checkbox,
          onChange: (e) => {
            const {checked} = e.target;
            setIsRead(checked);
          },
        },
      }[field];
    },
    [
      buyerCityOptions,
      buyerTownOptions,
      isReceiverSameAsBuyer,
      receiverCityOptions,
      receiverTownOptions,
      onDisclaimerDetailClick,
      isRead,
      shouldUpdateProfile,
      values,
      zip3,
    ],
  );

  function getData(_values, isSame) {
    if (isSame) {
      return {
        ..._values,
        receiver_name: _values.buyer_name,
        receiver_address: _values.buyer_address,
        receiver_phone: _values.buyer_phone,
        receiver_email: _values.buyer_email,
        receiver_city: _values.buyer_city,
        receiver_district: _values.buyer_district,
        receiver_zip: _values.buyer_zip,
      };
    } else {
      return _values;
    }
  }

  function formValidate(values) {
    let passed = true;
    Object.keys(values).forEach((f) => {
      const inputProps = getInputProps(f);
      if (inputProps) {
        if (inputProps.rules.some((r) => r.required === true) && !values[f]) {
          message.error(
            inputProps.rules.find((r) => r.required === true).message,
          );
          passed = false;
        }
        if (
          inputProps.rules.some((r) => !!r.pattern) &&
          !!values[f] &&
          !inputProps.rules.find((r) => !!r.pattern).pattern.test(values[f])
        ) {
          message.error(inputProps.rules.find((r) => !!r.pattern).message);
          passed = false;
        }
      }
    });
    return passed;
  }

  async function goCheckout() {
    try {
      try {
        if (!formValidate(values)) {
          return;
        }
        AppActions.setLoading(true);

        await CartActions.editConfig(getData(values, isReceiverSameAsBuyer));

        try {
          if (shouldUpdateProfile) {
            const _profile = await AppActions.updateProfile({
              name: values.buyer_name,
              zip: values.buyer_zip,
              city: values.buyer_city,
              district: values.buyer_district,
              phone: values.buyer_phone,
              address: values.buyer_address,
            });
            setUser({...user, data: {..._profile}});

            message.success('會員資料已同步');
          }
        } catch (err) {
          message.warn('無法同步會員資料，將繼續進行結帳流程...');
        }
      } finally {
        AppActions.setLoading(false);
      }
      AppActions.setLoading(true);
      let _url = await CartActions.getCheckoutUrl();
      window.open(_url, '_self');
    } catch (err) {
      console.log('debug', err);
    }
  }

  const extraHintText = React.useMemo(() => {
    if (home && user && user.data) {
      if (user.data.membership !== MEMBERSHIP.normal) {
        return home[`cart_giveaway_hint_${user.data.membership}`];
      }
    }

    return '';
  }, [home, user]);

  if (!cart || !cart.owner || !user) {
    return (
      <Wrapper mobile={mobile}>
        <TopSection>
          <h2>我的購物車</h2>
          <div>
            <h3 style={{margin: '10px 0'}}>正在取得資料...</h3>
          </div>
        </TopSection>
      </Wrapper>
    );
  }

  return (
    <Wrapper mobile={mobile}>
      <TopSection>
        <h2>我的購物車</h2>

        {cart.items.length === 0 && (
          <div>
            <h3 style={{margin: '10px 0'}}>
              購物車內沒有商品，可點選右上角 Shop Now 選購！
            </h3>
            {/*FIXME: should change back*/}
            <Button onClick={() => navigate('/products?cat=logistics')}>
              馬上選購
            </Button>
          </div>
        )}
        {cart.items.map((cartItem, idx) => (
          <CartItem item={cartItem} itemIdx={idx} key={idx} editable />
        ))}

        {cart.items.length > 0 && (
          <Fragment>
            <div
              style={{
                height: 1,
                background: '#846E4F',
                marginTop: 30,
                marginBottom: 20,
              }}
            />

            {home?.cart_giveaway_hint_normal && (
              <p
                style={{
                  whiteSpace: 'pre-line',
                  wordBreak: 'break-word',
                  maxWidth: 300,
                  marginLeft: 'auto',
                  color: 'rgba(132, 110, 79, 1)',
                  marginTop: 5,
                }}>{`${home.cart_giveaway_hint_normal}`}</p>
            )}

            {extraHintText && (
              <p
                style={{
                  whiteSpace: 'pre-line',
                  wordBreak: 'break-word',
                  maxWidth: 300,
                  marginLeft: 'auto',
                  color: 'rgba(132, 110, 79, 1)',
                  marginTop: 5,
                }}>{`${extraHintText}`}</p>
            )}

            <div className="field">
              <div className="name">小計</div>
              <div className="value">$ {cart.subtotal}</div>
            </div>
            {cart.extra_items.map((e, i) => (
              <div className="field" key={i}>
                <div className="name">{e.name}</div>
                <div className="value">$ {e.amount}</div>
              </div>
            ))}
            <div className="field total">
              <div className="name">總金額</div>
              <div className="value">$ {cart.total}</div>
            </div>
          </Fragment>
        )}
      </TopSection>

      <Divider />

      {cart.items.length > 0 && (
        <BottomSection>
          <div className="top">
            <div className="left">
              <h2 className="col-name">購買資訊</h2>

              <InputGroup>
                <GeneralInput {...getInputProps('buyer_city')} />
                <div style={{flexBasis: 18}} />
                <GeneralInput {...getInputProps('buyer_district')} />
              </InputGroup>

              <GeneralInput {...getInputProps('buyer_address')} />

              <InfoField>
                <div className="name">貼心提醒</div>
                <ChevronRight color={'#E5E5EA'} size={24} />
                <div className="value">目前不提供離島運送</div>
              </InfoField>

              <InfoField>
                <div className="name">運送方式</div>
                <ChevronRight color={'#E5E5EA'} size={24} />
                <div className="value">
                  {LOGISTIC_TYPE_DISPLAY[cart.logistic_type]}
                </div>
              </InfoField>
              <InfoField>
                <div className="name">酌收運費</div>
                <ChevronRight color={'#E5E5EA'} size={24} />
                <div className="value">{cart.fee} 元</div>
              </InfoField>
              {/* <InfoField>
                <div className="name">運送說明</div>
                <ChevronRight color={'#E5E5EA'} size={24} />
                <div className="value">
                  7/15開始出貨，因疫情影響，可能提早或是延後配送
                </div>
              </InfoField> */}
              <InfoField>
                <div className="name">付款方式</div>
                <ChevronRight color={'#E5E5EA'} size={24} />
                <div className="value">ATM虛擬帳號</div>
              </InfoField>
              <InfoField>
                <div className="name">付款說明</div>
                <ChevronRight color={'#E5E5EA'} size={24} />
                <div className="value">僅限使用台灣地區的銀行戶頭</div>
              </InfoField>
            </div>

            <div style={{flexBasis: 32}} />

            <div className="right">
              <h2 className="col-name">購買人資料</h2>
              <InputGroup>
                <GeneralInput {...getInputProps('buyer_name')} />
                <div style={{flexBasis: 18}} />
                <GeneralInput {...getInputProps('buyer_phone')} />
              </InputGroup>

              <div className="hint">
                ＊ 務必正確輸入購買人姓名確保正確送達。
              </div>

              <GeneralInput {...getInputProps('buyer_email')} />

              <div className="hint">＊ 我們會將您的訂單通知信寄送至此。</div>

              <GeneralInput {...getInputProps('order_note')} />

              {!isReceiverSameAsBuyer && (
                <Fragment>
                  <h2 className="col-name">收件人資料</h2>

                  <InputGroup>
                    <GeneralInput {...getInputProps('receiver_name')} />
                    <div style={{flexBasis: 18}} />
                    <GeneralInput {...getInputProps('receiver_phone')} />
                  </InputGroup>

                  <div className="hint">
                    ＊ 務必正確輸入購買人姓名確保正確送達。
                  </div>

                  <GeneralInput {...getInputProps('receiver_zip')} />

                  <InputGroup>
                    <GeneralInput {...getInputProps('receiver_city')} />
                    <div style={{flexBasis: 18}} />
                    <GeneralInput {...getInputProps('receiver_district')} />
                  </InputGroup>

                  <GeneralInput {...getInputProps('receiver_address')} />

                  <GeneralInput {...getInputProps('receiver_email')} />
                </Fragment>
              )}

              <GeneralInput {...getInputProps('isReceiverSameAsBuyer')} />
              <GeneralInput {...getInputProps('shouldUpdateProfile')} />
              <GeneralInput {...getInputProps('isRead')} />

              <div
                style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                {/*<AntButton onClick={updateCartConfig} disabled={!isModified}>*/}
                {/*  更新寄送資訊*/}
                {/*</AntButton>*/}
                {/*<AntButton*/}
                {/*  type="text"*/}
                {/*  onClick={revertChanges}*/}
                {/*  disabled={!isModified}>*/}
                {/*  復原*/}
                {/*</AntButton>*/}
                <div style={{flex: 1}} />
                <div
                  className="total"
                  style={{display: 'flex', alignItems: 'baseline'}}>
                  <div>實付金額</div>
                  <div style={{fontSize: '30px', marginLeft: 20}}>
                    $ {cart.total}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom">
            <div className="separate" />
            <Form.Item>
              <Button
                type="secondary"
                disabled={!isRead}
                onClick={goCheckout}
                style={{marginTop: 10, width: '100%', alignSelf: 'flex-end'}}>
                下一步
              </Button>
            </Form.Item>
          </div>
        </BottomSection>
      )}
      {modalElem}
    </Wrapper>
  );
}

const DisclaimerWrapper = styled.div`
  padding: 50px 55px;
  color: #111827;

  & > h2 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  & > p {
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopSection = styled.section`
  flex: 1;
  padding: 30px 50px;
  border: 1px solid #ddd;
  border-radius: 10px;

  & > .field {
    padding: 8px 20px;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    & > .name {
      font-size: 14px;
    }

    & > .value {
      font-size: 20px;
      color: #846e4f;
      min-width: 150px;
      text-align: right;
    }
  }

  & > .field.total {
    background-color: #1e2f29;
    color: #fff;

    & > .value {
      color: #fff;
      font-size: 30px;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    padding: 30px 20px;
    & > .field {
      justify-content: space-between;

      & > .name {
      }

      & > .value {
        padding-left: 0;
      }
    }
  }
`;

const BottomSection = styled.section`
  flex: 1;
  padding: 30px 50px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  & .disclaimer-link:hover {
    color: #ab9473;
  }

  & > .top {
    display: flex;
    flex-direction: row;

    & > .left,
    .right {
      & > h2.col-name {
        padding: 10px 0;
        border-bottom: 1px solid #000;
        margin-bottom: 15px;
      }
    }

    & > .left {
      flex: 1;
    }

    & > .right {
      flex: 2;

      & > .hint {
        font-size: 14px;
        color: #846e4f;
        margin: 5px 0;
      }
    }
  }

  & > .bottom {
    flex: 1;

    & > .separate {
      flex: 1;
      height: 1px;
      background: #846e4f;
      margin: 20px 0;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    padding: 10px 16px;
    & > .top {
      flex-direction: column;
    }
  }
`;

const Divider = styled.div`
  flex-basis: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const InputField = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const InfoField = styled.div`
  flex: 1;
  display: flex;
  font-size: 14px;
  color: #000;
  padding: 10px 15px;
  background-color: #fcfcfc;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export default withLoginRequired(CheckoutInfo, {
  admin: false,
  renderLoginRequired,
});
